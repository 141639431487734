@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

@import "./styles/study";
@import "./styles/questionPreview";

:root {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app-main {
  height: 100vh;
  width: 100vw;
}

::-webkit-scrollbar {
  width: 0.375rem;
  height: 0.375rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b8b8b8;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #929292;
}

a {
  text-decoration: none;
}

.quill-container {
  .ql-toolbar.ql-snow {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border: 1px solid #e5e7eb;
  }

  .ql-container.ql-snow {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    border: 1px solid #e5e7eb;

    .ql-editor {
      min-height: 150px;
    }
  }

  .ql-container.ql-bubble {
    .ql-editor {
      padding: 0;
    }
    .ql-tooltip {
      height: 0;
    }
  }
}

.quill-container.preview {
  .ql-editor {
    padding: 0;
  }
}

.phone-number-input {
  .flag-dropdown {
    .selected-flag:hover,
    .selected-flag:focus {
      background-color: transparent;
    }
  }
  .flag-dropdown.open {
    .selected-flag.open {
      background-color: transparent;
    }
  }
}

//scheduling styles globally
.datePickerContainer {
  flex: 1;
  min-width: 370px;

  & .MuiPickersCalendarHeader-switchViewButton {
    display: none;
  }
  & .MuiPickersCalendarHeader-root {
    padding: 0px;
    margin-top: 0px;
  }
  & .MuiDayPicker-header {
    margin: 10px 0px;
    justify-content: space-between;
  }

  & .MuiIconButton-root {
    color: #1c64f2;
  }
  & .MuiPickersFadeTransitionGroup-root {
    color: #6b7280;
    font-size: 18px;
  }

  & .MuiPickersDay-root {
    background-color: #ebf5ff;
    color: #1c64f2;
    font-weight: 600;
    font-size: 16px;

    &:focus,
    &.Mui-selected,
    &.Mui-selected:hover,
    &.Mui-selected:focus {
      background-color: #1c64f2;
      color: white;
    }
  }
  & .Mui-disabled {
    color: #6b7280;
    background-color: white;
  }
  & .MuiPickersDay-today::before {
    transform: translateX(-50%);
    border-radius: 100%;
    position: absolute;
    background: #1c64f2;
    bottom: 5px;
    height: 4px;
    content: "";
    width: 4px;
    left: 50%;
  }
  & .MuiPickersDay-today,
  .MuiPickersDay-today:not(.Mui-selected) {
    border: none;
  }

  & .MuiDayPicker-weekDayLabel {
    font-weight: 400;
    font-size: 16px;
    align-self: flex-start;
    margin: 0;
  }
  & .MuiDayPicker-weekContainer {
    justify-content: space-between;
    margin-top: 11px;
  }
}

.canvas-element {
  border: 1px solid #dcdce0;
  border-radius: 6px;
  height: 160px;
  width: 100%;
}

.ot-publisher {
  overflow: hidden;
  position: absolute;
  top: 25px;
  right: 45px;
  border: 1.6px solid #ffffff;
  border-radius: 4px;
  .OT_publisher {
    width: 126px !important;
    height: 156px !important;
    border-radius: 4px;
  }
}
