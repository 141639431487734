.app-main {
  .study-drawer {
    .item {
      display: flex;
      align-items: center;
      height: 40px;
      border-radius: 6px;
      margin-bottom: 10px;
      background: #262626;
      color: #b3b3b3;
      &.active {
        color: #ffffff;
        background: #515151;
        .econsent-svg {
          .econsent-svg-rect {
            fill: #515151;
          }
          .econsent-svg-path {
            fill: #ffffff;
          }
        }
      }
    }
  }
  .score-calculator.create {
    .question-input {
      border: none;
      background: transparent;
      outline: none;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      color: #30d073;
      width: 70px;
      text-align: center;
    }
    .operator-input {
      border: none;
      background: transparent;
      outline: none;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      width: 16px;
      color: #eb5757;
      text-align: center;
    }
    .const-input {
      border: none;
      background: transparent;
      outline: none;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      width: 3rem;
      color: #333333;
      text-align: center;
    }
  }
}
