.quill-container-lg {
  .ql-container.ql-snow {
    .ql-editor {
      min-height: 350px;
      overflow: auto;
      max-height: 50vh;
    }
  }
}

.quill-container-md {
  .ql-container.ql-snow {
    .ql-editor {
      min-height: 150px;
      max-height: 30vh;
    }
  }
}

.quill-container-sm {
  .ql-container.ql-snow {
    .ql-editor {
      min-height: 80px;
    }
  }
}

.quill-bubble-md {
  .ql-container.ql-bubble {
    font-size: 16px;
    font-weight: 400;
  }
}
